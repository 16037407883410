import React, { useRef, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import "./contractpdf.scss";

function Contractpdf({ data }) {
  const [showmodal, setShowmodal] = useState(false);

  const downloadRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => downloadRef.current,
  // });
  const handlePrint = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(`ET-${data[0].customer?._id?.slice(-6)}.pdf`);
    });
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(secureLocalStorage.getItem("Token"));

  const getExpiryDate = (offer, offerName) => {
    const startDate = new Date(offer.createdAt);
    const expiryDate = new Date(startDate);
    const digits = offerName.match(/\d+/g);
    const digit = digits ? parseInt(digits[0], 10) : null;
    expiryDate.setMonth(expiryDate.getMonth() + digit);
    return expiryDate?.toISOString().split("T")[0];
  };

  const UploadFileToS3 = async (file, fileFolderPath) => {
    console.log(file);
    // console.log(fileFolderPath);
    const convertedFile = await convertToBase64(file);
    console.log(convertedFile);
    return new Promise(async (resolve, reject) => {
      try {
        //Url set in the server. Server will return s3 object download url
        let url = `${apiUrl}/dealer/pdf-mail`;
        // let url = `${process.env.REACT_APP_API_URL}/aws-upload`;
        // setLoading(true);
        axios
          .post(
            url,
            {
              file: convertedFile,
              fileFolderPath: fileFolderPath,
              fileName: file.name,
              fileType: file.type,
              email: data[0].customer.email,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log("response after upload", response);
            // if (response.data.success == true) {
            console.log("response.data.file_url", response.data.file_url);
            resolve({
              success: true,
              thumbnail_url: response.data.file_url,
            });
            // } else {
            //   // setLoading(false);
            //   reject({ success: false, error: response.data.error });
            // }
          })
          .catch((err) => {
            console.log(err);
            // setLoading(false);
            reject({ success: false, error: err });
            toast.error(`Error! Please try again`, { toastId: "awsError" });
          });
      } catch (error) {
        console.log(error);
        // setLoading(false);
        reject({ success: false, error });
        toast.error(`Error! Please try again`, { toastId: "awsError" });
      }
    });
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };
  // const handlePDFEmail = () => {
  //   const input = document.getElementById('divToPrint');
  //   html2canvas(input)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const contentWidth = 250; // Adjust based on the size of your content
  //       const contentHeight = 1050;
  //       const marginLeft = 5;
  //       const marginTop = 25;
  //       const imageWidth = contentWidth + 2 * marginLeft;
  //       const imageHeight = contentHeight + 2 * marginTop;
  //       const pdf = new jsPDF();
  //       // pdf.addImage(imgData, 'JPEG', 0, 0);
  //       pdf.addImage(imgData, 'JPEG', marginLeft, marginTop, contentWidth, contentHeight, '', 'FAST');
  //       console.log(pdf)
  //       // Convert the PDF to a Blob
  //       const blob = pdf.output('blob');

  //       // Pass the Blob to the function for uploading to S3
  //       UploadFileToS3(blob, "/pdfs").then(res => {
  //         console.log(res)
  //       }).catch(err => {
  //         console.log(err)
  //       })
  //     });
  // }

  // const File = event.target.files[0];
  // console.log(File);
  // const fileFolderPath = "audio";

  // if (File) {
  //   UploadFileToS3(File, fileFolderPath)
  //     .then((result) => {
  //       // setLoading(true);
  //       console.log("Upload successful:", result);
  //       // handleGetGptResponse(result.thumbnail_url);
  //     })
  //     .catch((error) => {
  //       console.error("Upload failed:", error);
  //     });
  // } else {
  //   console.error("No file selected.");
  // }

  // const handlePDFEmail = () => {
  //   const input = document.getElementById('divToPrint');
  //   const contentWidth = 250; // Adjust based on the size of your content
  //   const contentHeight = 1050;
  //   const marginLeft = 5;
  //   const marginTop = 25;
  //   const maxImageHeight = 800; // Maximum height for an image on a single page

  //   const pdf = new jsPDF({
  //     unit: 'px', // Use pixels for units
  //     format: 'a4', // Set the format to A4
  //   });

  //   let yPos = marginTop;

  //   // Function to add a new page
  //   const addNewPage = () => {
  //     pdf.addPage();
  //     yPos = marginTop;
  //   };

  //   const addImageWithPageBreak = (imgData, imageHeight) => {
  //     // Check if the content exceeds the remaining space on the current page
  //     if (yPos + imageHeight > pdf.internal.pageSize.height - marginTop) {
  //       // Calculate the required height for the content
  //       const requiredHeight = Math.ceil(imageHeight / (pdf.internal.pageSize.height - marginTop)) * pdf.internal.pageSize.height;

  //       // Add a new page with the required height
  //       pdf.addPage({
  //         format: [pdf.internal.pageSize.width, requiredHeight]
  //       });

  //       yPos = marginTop;
  //     }

  //     pdf.addImage(imgData, 'JPEG', marginLeft, yPos, contentWidth, contentHeight, '', 'FAST');
  //     yPos += imageHeight;

  //     // Check if the image height alone exceeds the maximum height
  //     if (imageHeight > maxImageHeight) {
  //       addNewPage();
  //     }
  //   };

  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const imageHeight = contentHeight + 2 * marginTop;

  //     // Add the first image with page break check
  //     addImageWithPageBreak(imgData, imageHeight);

  //     console.log(pdf);

  //     // Convert the PDF to a Blob
  //     const blob = pdf.output('blob');

  //     // Pass the Blob to the function for uploading to S3
  //     toast.success("Email Sent")
  //     UploadFileToS3(blob, "/pdfs").then((res) => {
  //       console.log(res);
  //     }).catch((err) => {
  //       console.log(err);

  //       toast.error(err)
  //     });
  //   });
  // };

  const handlePDFEmail = () => {
    // console.log("Chal jaa");
    const input = document.getElementById("divToPrint");
    const pdf = new jsPDF();

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdfWidth = pdf.internal.pageSize.getWidth() - 50;
      const pdfHeight = pdf.internal.pageSize.getHeight() + 100;
      const imgWidth = pdfWidth - 20; // Reduced width with margin
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      // Calculate the x coordinate for a centered positioning
      const xMargin = (pdfWidth - imgWidth) / 2;
      let position = 0;

      pdf.addImage(imgData, "PNG", xMargin, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;

      // Add new pages if the content overflows
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", xMargin, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }

      // Save the PDF
      // pdf.save(`ExtrendedTrust${}.pdf`);
      // pdf.save(`ET-${data[0].customer?._id?.slice(-6)}.pdf`);

      const blob = pdf.output("blob");

      UploadFileToS3(blob, "/pdfs")
        .then((res) => {
          console.log(res);
          setShowmodal(true);
          // toast.success("Email Sent")
        })
        .catch((err) => {
          console.log(err);

          toast.error(err);
        });
    });
  };

  return (
    <>
      <div className="btn-wrapper px-5 d-flex justify-content-end ">
        {/* <EditRegistrants onPrint={handlePrint} onEmail={handlePDFEmail} /> */}
        <button
          type="button"
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          style={{ width: "200px", height: "60px" }}
          onClick={handlePrint}
        >
          Print
        </button>

        <button
          type="button"
          className="btn theme-btn-1 btn-effect-1 text-uppercase btn1"
          style={{
            padding: "15px 30px",
            backgroundColor: "rgb(255, 90, 60)",
            color: "#FFF",
            // marginBottom: "30px",
            // marginLeft: "40px",
            fontSize: "16px",
          }}
          onClick={() => {
            // <Contractpdf />
            handlePDFEmail();
          }}
        >
          Email PDF to Client
        </button>
      </div>
      <section
        className="contractpdf-sec d-none"
        ref={downloadRef}
        id="divToPrint"
      >
        <div className="">
          <img src="/assets/img/logoblack.svg" alt="..." className="pdf-logo" />
          {/* <p>{`${data.createdAt}`}</p> */}
          <div className="top-info">
            <p>{`${data[0]?.customer?.firstName} ${data[0]?.customer?.lastName}`}</p>
            <p>{`${data[0]?.customer?.address}`}</p>
            <p>{`${data[0]?.customer?.state}, ${data[0]?.customer?.city}`}</p>
            <p>{`${data[0]?.customer?.postalCode}`}</p>
          </div>
          <p>
            We appreciate your recent enrollment in the Extended Trust service
            agreement and would like to express our gratitude for choosing our
            services. It is essential to carefully examine the agreement,
            including both the cover page and the attached Terms and Conditions,
            to ensure a comprehensive understanding of the services we offer.
          </p>
          <p>
            {" "}
            <strong style={{ textDecoration: "underline" }}>
              It's important to note that the Extended Trust service agreement
              is not a replacement for insurance. We recommend consulting with
              an insurance specialist to ensure that you have the appropriate
              insurance coverage for your needs.
            </strong>
          </p>
          <p>
            Should you have any inquiries about your Extended Trust service
            agreement, please feel free to contact us via phone at 778-229-8847
            or through email at{" "}
            <a href="#" style={{ textDecoration: "none", color: "black" }}>
              support@extendedtrust.ca
            </a>
          </p>
          <div>
            <p>Thank you,</p>
            <p>Extended Trust Inc.</p>
          </div>
          <div className="car-div">
            <img src="/assets/img/C3.png" alt="..." className="car-img" />
            <span>
              * This is a sample image of your vehicle. Colour and models may
              not always be accurate.
            </span>
          </div>
          <p>
            For your convenience, please keep the enclosed wallet card handy,
            containing contact details for customer assistance. In the event
            that you require services outlined in this agreement, kindly present
            this card to your authorized service facility for assistance.
          </p>
          <div className="detail-box">
            <div className="left">
              <div className="img-div">
                <img src="/assets/img/logoblack.svg" alt="..." />
              </div>
              <h3>CUSTOMER SERVICE CARD</h3>
              <div className="cust-det">
                <div className="ist">
                  <p>Customer Name:</p>
                  <p>{`${data[0]?.customer?.firstName} ${data[0]?.customer?.lastName}`}</p>
                </div>
                <div className="second">
                  <p>Extended #:</p>
                  <p>{`ET-${data[0]?.customer?._id?.slice(-6)}`}</p>
                </div>
                <div className="third">
                  <p>Issuing Dealer:</p>
                  <p>{`${data[0]?.dealer?.firstName} ${data[0]?.dealer?.lastName}`}</p>
                </div>
              </div>
            </div>
            <div className="right">
              <h4>
                TO BOOK A SERVICE OR <br />
                FOR CUSTOMER ASSISTANCE <br /> PLEASE CALL 778-229-8847
              </h4>
              <span>
                Approved repair work will be paid by credit card <br /> if
                service facility prefers.
              </span>
            </div>
          </div>

          <div className="detait-content">
            <div className="det-top">
              <img
                src="/assets/img/logoblack.svg"
                alt="..."
                className="second-logo"
              />
              <span>{`Extended # : ET-${data[0]?.customer?._id?.slice(
                -6
              )}`}</span>
            </div>
            <div className="customer-info">
              <h5 className="head">Customer Information</h5>
              <div className="info-detail">
                <div className="name">
                  <h5>Name</h5>
                  <p>{`${data[0]?.customer?.firstName} ${data[0]?.customer?.lastName}`}</p>
                </div>
                <div className="phone">
                  <h5>Phone</h5>
                  {data[0]?.customer?.phone && (
                    <p>{`${data[0]?.customer?.phone}`}</p>
                  )}
                </div>
                <div className="email">
                  <h5>Email Address</h5>
                  <p>{`${data[0]?.customer?.email}`}</p>
                </div>
                <div className="address">
                  <h5>ADDRESS</h5>
                  <p>{`${data[0]?.customer?.address}`}</p>
                </div>
              </div>
            </div>
            <div className="vehicle-info">
              <h5 className="head">Vehicle Information</h5>
              <div className="info-detail">
                <div className="date">
                  <h5>VEHICLE PURCHASE/LEASE DATE</h5>
                  <p>{`${data[0]?.customer?.vehical?.purchaseDate
                    ?.split("")
                    ?.filter((_, index) =>
                      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(index)
                    )
                    .join("")}`}</p>
                </div>
                <div className="year">
                  <h5>Year</h5>
                  <p>{`${data[0]?.customer?.vehical?.year?.slice(0, -20)}`}</p>
                </div>
                <div className="manufacturer">
                  <h5>MANUFACTURER</h5>
                  <p>{`${data[0]?.customer?.vehical?.make}`}</p>
                </div>
                <div className="model">
                  <h5>MODEL</h5>
                  <p>{`${data[0]?.customer?.vehical?.modal}`}</p>
                </div>
                <div className="number">
                  <h5>VEHICLE IDENTIFICATION NUMBER</h5>
                  <p>{`${data[0]?.customer?.vehical?.vin}`}</p>
                </div>
              </div>
            </div>
            <div className="services-info">
              <h5 className="head">Services Information</h5>
              <div className="info-detail">
                <table>
                  <thead>
                    <tr>
                      <th>SERVICES INCLUDED</th>
                      <th>TERM</th>
                      <th>EXPIRY DATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data[0] && data[0]?.offers ? (
                      data[0]?.offers.map(
                        (item, index) =>
                          // item.offers.map((offer, offerindex) => {\
                          item?.services?.map((serviceitem, serviceindex) => {
                            return (
                              <tr key={`offer-${serviceindex}`}>
                                <td>
                                  <span>
                                    {serviceitem.serviceType}{" "}
                                    {serviceitem.serviceName}
                                  </span>
                                </td>
                                <td>{item.offerName}</td>
                                <td>
                                  {getExpiryDate(data[0], item?.offerName)}
                                </td>
                              </tr>
                            );
                          })
                        // })
                      )
                    ) : (
                      <tr>
                        <td colSpan="2">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <div className="heading-div">
                <h5>SERVICES INCLUDED</h5>
                <h5>TERM</h5>
                <h5>EXPIRY DATE</h5>
              </div>
              {contact && contact.length > 0 && service && service.length > 0 ? (
                contact.map((item, index) => (


                  item.offers.map((offer, offerindex) => {
                    return (
                      <>
                        <p key={offer?.offerName}>{offer?.offerName}</p>
                        {offer?.services?.map((serviceitem, serviceindex) => {
                          return (<div key={serviceindex}>
                            {
                              (() => {
                                const foundItem = service.find(item => item?._id === serviceitem);
                                return (foundItem ? <p>     {foundItem.serviceName} </p> : <></>)

                              })()
                            }
                          </div>
                          )

                          // <p>Service Name: {service.find(item => item._id === serviceitem)?.serviceName}</p>
                        }
                        )}
                      </>
                    )
                  })

                  // {
                  //   item.services.map((service) => [
                  //     <option key={service?.serviceName}>{service?.serviceName}</option>,
                  //   ])
                  // }

                ))
              ) : <></>} */}
              </div>
            </div>
            <div className="selling-dealer">
              <h5 className="head">SELLING DEALER </h5>
              <div className="info-detail">
                <div className="d-name">
                  <h5>DEALERSHIP NAME </h5>
                  <p>{`${data[0]?.dealer?.firstName} ${data[0]?.dealer?.lastName}`}</p>
                </div>
                <div className="d-person">
                  <h5>DEALERSHIP CONTACT PERSON</h5>
                  <p>{`${data[0]?.dealer?.firstName} ${data[0]?.dealer?.lastName}`}</p>
                </div>
                <div className="telephone">
                  <h5>TELEPHONE</h5>
                  <p>{`${data[0]?.dealer?.phone} `}</p>
                </div>
                <div className="address">
                  <h5>ADDRESS</h5>
                  <p>{`${data[0]?.dealer?.address} `}</p>
                </div>
              </div>
            </div>
            <div className="our-info">
              <h5 className="head">Extended Trust INC.</h5>
              <div className="info-detail">
                <div className="left">
                  <p>
                    Unit 200-11729 105 Ave. Edmonton, AB T5H0L9
                  </p>
                </div>
                <div className="right">
                  <p>Phone: 778-229-8847</p>
                  <p>Fax: 778-229-8847</p>
                </div>
              </div>
            </div>
          </div>
          <h5 className="caution">PLEASE READ CAREFULLY BEFORE SIGNING</h5>

          <p>
            THIS CONTRACT COMMENCED ON THE CONTRACT PURCHASE DATE AS SHOWN BELOW
            SUBJECT TO AUTHORIZATION BY THE SELLING DEALER AND PAYMENT OF THE
            CONTRACT PRICE. PLEASE READ THIS CONTRACT FOR FULL DETAILS. Extended
            Trust INC. RESERVES THE RIGHT TO CANCEL THIS CONTRACT ON OUR BEHALF
            AT ANY TIME FOR A REFUND (UNDER TERMS OUTLINED WITHIN THIS CONTRACT)
            IF ANY INFORMATION LISTED ON THE CONTRACT REGISTRATION PAGE IS FOUND
            TO BE INACCURATE OR FALSIFIED, IF THERE IS MISREPRESENTATION IN
            OBTAINING THIS CONTRACT, IF THERE IS INTENTIONAL ABUSE OF A SERVICE
            BY YOU OR YOUR AUTHORIZED DESIGNATE, OR IF THE LISTED VEHICLE (OR
            ITS USE) IS INELIGIBLE FOR REASONS OUTLINED HEREIN AND IN THE TERMS
            AND CONDITIONS.
          </p>
          <p>
            THIS CONTRACT SHALL BE VOID AND OF NO FORCE OR EFFECT IF THE
            CONTRACT PURCHASE DATE IS MORE THAN THIRTY (30) DAYS AFTER THE DATE
            THAT YOU PURCHASED OR LEASED THE VEHICLE FROM THE SELLING DEALER.
          </p>
          <p>THE CUSTOMER AGREES TO PURCHASE THIS VEHICLE SERVICE CONTRACT.</p>
          <p>
            <strong>
              THE CUSTOMER ACKNOWLEDGES THAT HE/SHE HAS READ AND AGREES TO THE
              TERMS OF THIS CONTRACT, INCLUDING THE SCHEDULE OF TERMS AND
              CONDITIONS.
            </strong>
          </p>
          <div className="signs">
            <div className="ist">
              <p className="date"></p>
              <p className="date"></p>
              <p className="date">
                {data[0]?.customer?.vehical?.purchaseDate &&
                  new Date(
                    data[0]?.customer?.vehical?.purchaseDate
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
              </p>
            </div>
            <div className="third">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="second">
              <span>
                SIGNATURE OF AUTHORIZED REPRESENTATIVE OF SELLING DEALER ON
                BEHALF OF Extended Trust INC.
              </span>
              <span>CUSTOMER SIGNATURE</span>
              <span>CONTRACT PURCHASE DATE</span>
            </div>
          </div>
          <div className="terms">
            <h5>TERMS AND CONDITIONS</h5>
            <p className="first-para">
              These Terms and Conditions together with the completed
              Registration Page constitute the entire agreement between You and
              Us relating to the subject matter hereof. The sections and
              paragraph headings used in this Contract are used for convenience
              only and do not in any way affect the interpretation of this
              Contract.
            </p>
            <h6>1. DEFINITIONS</h6>
            <p className="first-para">
              In this Contract, the following terms shall have the meaning set
              opposite each:
            </p>
            <div className="definitions-div">
              <p>
                <strong>Chemical Causes –</strong> relates to imperfections
                caused as a result of exposure to any type of chemical,
                including, but not limited to, solvents, cleaning agents or
                paint.
              </p>
              <h6>1. DEFINITIONS</h6>
              <p className="first-para">
                In this Contract, the following terms shall have the meaning set
                opposite each:
              </p>
              <div className="definitions-div">
                <p>
                  <strong>Chemical Causes –</strong> relates to imperfections
                  caused as a result of exposure to any type of chemical,
                  including, but not limited to, solvents, cleaning agents or
                  paint.
                </p>
                <p>
                  <strong>Detailing –</strong> refers to the application of
                  techniques or materials employed by Extended Trust in the
                  course of its normal business for the purposes of addressing
                  Panel Imperfection, Interior Imperfections, and Cosmetic Rim
                  Imperfections (if this optional service is purchased).
                  ”Detailing” shall not include the application of techniques or
                  materials to address issues outside the definitions of the
                  specific imperfections referenced in this definition,
                  including but not limited to imperfections resulting from
                  Chemical Causes, Environmental Causes, Pre-Existing
                  Imperfections, as well as any other exclusions listed
                  throughout this Contract.
                </p>
                <p>
                  <strong>Environmental Causes –</strong> relates to
                  imperfections resulting from the exposure of the Vehicle to
                  the elements and other environmental issues, including, but
                  not limited to, rain, snow, water, sun, hail, smoke, mould,
                  mildew, or wind-blown objects
                </p>
                <p>
                  <strong>Fee –</strong> The Fee shall be a sum agreed upon
                  between Extended Trust and the Dealer, excluding GST, PST, and
                  any other taxes. For the sake of clarity, the Fee referred to
                  in this Contract shall be based on the per contract price that
                  was paid to Extended Trust by the Dealer and shall not have
                  any relationship to any amount stipulated for the services
                  included in this Contract that was paid by You to the Dealer.
                  Pre-Existing Imperfections – relates to imperfections existing
                  on or in respect of the Vehicle prior to the commencement of
                  the Term. Registration Page - means the numbered document
                  which is the cover page and forms part of this Contract. It
                  lists information regarding You, the Vehicle, the Services
                  purchased and other relevant information.
                </p>
                <p>
                  <strong>Services -</strong> means the services that You have
                  purchased, as shown on the Registration Page.
                </p>
                <p>
                  <strong>Vehicle -</strong> means the vehicle described on the
                  Registration Page.
                </p>
                <p>
                  <strong>Coverage –</strong> means any Coverage provided by the
                  Vehicle’s original manufacturer, any seller’s Coverage or
                  guarantee, any insurance, any third party Coverage and any
                  extended service contract, but for the sake of clarity, does
                  not include this Contract.
                </p>
              </div>
              <p className="first-para">
                Any reference to the terms “We”, “Us” mean Extended Trust Inc.
                Any reference to the terms “You” and “Your” mean the Customer
                whose information appears on the Registration Page or the person
                to whom this Contract is then transferred in accordance with the
                transfer provisions of this Contract.
              </p>
              <h6>2. CONTRACT TERM</h6>
              <p className="first-para">
                This Contract shall commence upon the Contract Purchase Date and
                shall expire upon the end of the term selected on the
                Registration Page.
              </p>
              <h6>3. SERVICES</h6>
              <p className="first-para">
                The Services Information section of the Registration Page
                specifically identifies the particular Service applicable to
                this Contract that You have purchased. Such Services are more
                fully described within the Schedule of Services.
              </p>
              <h6>4. LIMITS OF LIABILITY; DISCLAIMERS</h6>
              <p className="first-para">
                Our maximum, combined liability under or in connection with this
                Contract shall in no event exceed the applicable limits set out
                in the Schedule of Terms and Conditions as applicable to the
                Services. Except as expressly provided in this Contract. We
                expressly disclaim and shall not be bound by any representation,
                condition, statement or Coverage, whether express or implied,
                statutory or otherwise, including without limitation, any
                Coverage of merchantability or fitness for particular purpose.
                In no event shall We be liable for any special, indirect, or
                consequential damages.
              </p>
              <h6>5. TERRITORY</h6>
              <p className="first-para">
                This Contract applies only to loss or damage that occurs, and
                repairs made, within Canada and the contiguous United States of
                America and Alaska.
              </p>
              <h6>6. SCHEDULE OF SERVICES</h6>
              <p className="first-para">
                This Contract applies only to imperfections that occur, and
                repairs made within Canada.
              </p>
              <h6 className="under-text">Service Details</h6>
              <p className="first-para">
                Subject to the terms and conditions of this Contract, including
                without limitation, the applicable exclusions and limits
                contained in this section 6 and elsewhere under this Contract,
                some or all of the following services (as applicable to the
                Services as shown on the Registration Page) will be provided by
                Us for the Vehicle during the term of this Contract. You or the
                selling dealer representative must contact Us in order to
                request any Services to which You are entitled. <br />
                We shall have no obligation or liability for repairs not
                authorized in advance by Us. Replacement parts may be new,
                remanufactured or of like kind and quality at the sole
                discretion of Us.
              </p>
              <h6 className="under-text">
                Plan A - Trust Care Scratch Services
              </h6>
              <p className="first-para">
                Imperfections in the form of a scratch or scrape of any
                accessible exterior painted factory body panel, excluding tires,
                wheels, rims, aftermarket body panels, discoloration, Chemical
                Damage, Environmental Damage, Pre-Existing Damage, scratches in
                excess of twelve (12) inches in length or an imperfection that
                is too deep (e.g., through to the primer coat or to sheet metal
                surface) to repair using Small to Medium Area Repair Techniques
                (SMART) employed by Extended Trust.
              </p>
            </div>
            <p className="first-para">
              Any reference to the terms “We”, “Us” mean Extended Trust Inc. Any
              reference to the terms “You” and “Your” mean the Customer whose
              information appears on the Registration Page or the person to whom
              this Contract is then transferred in accordance with the transfer
              provisions of this Contract.
            </p>
            <h6>2. CONTRACT TERM</h6>
            <p className="first-para">
              This Contract shall commence upon the Contract Purchase Date and
              shall expire upon the end of the term selected on the Registration
              Page.
            </p>
            <h6>3. SERVICES</h6>
            <p className="first-para">
              The Services Information section of the Registration Page
              specifically identifies the particular Service applicable to this
              Contract that You have purchased. Such Services are more fully
              described within the Schedule of Services.
            </p>
            <h6>4. LIMITS OF LIABILITY; DISCLAIMERS</h6>
            <p className="first-para">
              Our maximum, combined liability under or in connection with this
              Contract shall in no event exceed the applicable limits set out in
              the Schedule of Terms and Conditions as applicable to the
              Services. Except as expressly provided in this Contract. We
              expressly disclaim and shall not be bound by any representation,
              condition, statement or Coverage, whether express or implied,
              statutory or otherwise, including without limitation, any Coverage
              of merchantability or fitness for particular purpose. In no event
              shall We be liable for any special, indirect, or consequential
              damages.
            </p>
            <h6>5. TERRITORY</h6>
            <p className="first-para">
              This Contract applies only to loss or damage that occurs, and
              repairs made, within Canada and the contiguous United States of
              America and Alaska.
            </p>
            <h6>6. SCHEDULE OF SERVICES</h6>
            <p className="first-para">
              This Contract applies only to imperfections that occur, and
              repairs made within Canada.
            </p>
            <h6 className="under-text">Service Details</h6>
            <p className="first-para">
              Subject to the terms and conditions of this Contract, including
              without limitation, the applicable exclusions and limits contained
              in this section 6 and elsewhere under this Contract, some or all
              of the following services (as applicable to the Services as shown
              on the Registration Page) will be provided by Us for the Vehicle
              during the term of this Contract. You or the selling dealer
              representative must contact Us in order to request any Services to
              which You are entitled. <br />
              We shall have no obligation or liability for repairs not
              authorized in advance by Us. Replacement parts may be new,
              remanufactured or of like kind and quality at the sole discretion
              of Us.
            </p>
            <h6 className="under-text">Plan A - Trust Care Scratch Services</h6>
            <p className="first-para">
              Imperfections in the form of a scratch or scrape of any accessible
              exterior painted factory body panel, excluding tires, wheels,
              rims, aftermarket body panels, discoloration, Chemical Damage,
              Environmental Damage, Pre-Existing Damage, scratches in excess of
              twelve (12) inches in length or an imperfection that is too deep
              (e.g., through to the primer coat or to sheet metal surface) to
              repair using Small to Medium Area Repair Techniques (SMART)
              employed by Extended Trust.
            </p>
            <h6 className="under-text">Plan B - Trust Care Dent Services</h6>
            <p className="first-para">
              Imperfections in the form of dents or dings to accessible exterior
              painted factory sheet metal body panels that can be remedied by
              the methods employed by Extended Trust. Does not include issues
              resulting from aftermarket alterations, Environmental Causes,
              Chemical Causes, Pre-Existing Imperfections, inaccessible
              imperfections, imperfections on metal which has been creased or
              edges where manufacturer bracing does not allow for repair using
              the paint-less dent repair method employed by Extended Trust,
              damages with chipped paint, or dents in excess of four (4) inches
              in width or length, or imperfections that are too deep to repair
              using the paint-less dent repair method employed by Extended
              Trust.
            </p>
            <h6 className="under-text">
              Plan C - Trust Care Interior Services
            </h6>
            <p className="first-para">
              Refers to imperfections to factory interior upholstery, including
              leather, vinyl, and cloth, but does not include cuts or tears in
              excess of two (2) inches in length, Chemical Causes, Environmental
              Causes, Pre-Existing Causes.
            </p>
            <h6 className="under-text">
              Plan D - Trust Care Paint Chip Touch-Up Services
            </h6>
            <p className="first-para">
              Imperfections in the form of a rock chip of any accessible
              exterior painted factory body panels, excluding tires, wheels,
              rims, aftermarket body panels, discoloration, Chemical Damage,
              Environmental Damage, Pre-Existing Damage, rock chips in excess of
              three (3) mm in length or an imperfection that cannot be repaired
              using the Rock Chip Repair method employed by Extended Trust.
            </p>
            <h6 className="under-text">
              Plan E - Trust Care Front Windshield Chip-related Services
            </h6>
            <p className="first-para">
              Includes the cost of repairs to the front windshield ONLY of minor
              chips and cracks caused by propelled rocks or other propelled road
              debris such as wood debris, metal parts, plastic or composite
              scraps or any other propelled object. NOTE: this includes only
              repairable chips and cracks. Stress cracks or cracks over six (6)
              inches are not included. Windshield repair is a permanent process
              that removes air from the from the break and fills it with a
              curable resin. A windshield chip or crack repair is used to
              prevent further damage to the windshield and to restore the
              structure integrity of the windshield. Repaired damage will not
              always restore the windshields appearance to the same condition as
              prior to it being damaged. Not Included: Any repair that is
              covered by Coverage, recall, or acknowledgement of responsibility
              issued by the manufacturer. Weather related damage is NOT
              included. Damage in the view of sensors such as rain sensors, lane
              departure warning systems, automatic braking systems, or other
              automated driver assistance systems (ADAS) related components are
              NOT repairable. These sensors require crystal clear view and even
              a near perfect repair may cause malfunctions.
            </p>
            <div>
              <h6>7. CONTRACT EXCLUSIONS </h6>
              <p className="first-para">
                This Contract shall have no application to, and included
                Services shall not be provided in respect of, any of the
                following:
              </p>
            </div>
            <ol type="1">
              <li>
                For any repair or replacement involving the Vehicle when the
                damage or condition existed prior to the Contract Purchase Date.
              </li>
              <li>
                For any repair or replacement not specifically covered in the
                Schedule of Services, or for any repair or replacement that is
                specifically covered in the Schedule of Services but for which
                You have not purchased the services as identified on the
                Registration Page.
              </li>
              <li>
                For any loss or damage that occurs following the expiration or
                earlier termination of this Contract.
              </li>
              <li>
                For any loss or damage caused by or resulting from any single or
                multi-vehicle motor vehicle accident or collision regardless of
                cause or fault, or driver error.
              </li>
              <li>
                For any loss or damage caused by or resulting from fire,
                vandalism, riot, theft, protest, terrorism, explosion,
                lightning, earthquake, freezing, rust or corrosion, windstorm,
                tornado, hail, water, flood, sinkhole. or mudslide.
              </li>
              <li>
                For any repairs or replacements necessitated as a result of
                manufacturer defects.
              </li>
              <li>
                For any loss or damage caused by or resulting from willful or
                intentional damage, misuse, abuse, negligence, racing, or
                competitive driving, lack of normal maintenance required by the
                manufacturer's maintenance schedule for the Vehicle, or improper
                servicing by You or any other repairer; or for any loss or
                damage caused as a result of any failure to take reasonable
                precaution to care for and/or protect the components covered by
                this Contract; or any loss or damage not considered to be
                accidental as determined by Us.
              </li>
              <li>
                For any loss or damage caused by or resulting from alterations
                made to the Vehicle or if You are using or have used the Vehicle
                in a manner not recommended by Us or the manufacturer.
              </li>
              <li>
                For any loss or damage to parts not originally equipped by the
                manufacturer or recommended by the manufacturer for the Vehicle,
                including but not limited to custom wheels/rims, aftermarket
                accessory kits, or seat protectors.
              </li>
              <li>
                For any liability for property damage, or for injury to or death
                of any person arising out of the operation, maintenance or use
                of the Vehicle described in this Contract, whether or not
                related to the parts covered or for loss of use, time, profit,
                inconvenience, or any other consequential loss or damage.
              </li>
              <li>
                For loss or damage when the responsibility for the repair or
                replacement is covered under the original Coverage from the
                manufacturer or any insurance policy or parts Coverage, or a
                repair’s guarantee/Coverage.
              </li>
              <li>
                For any loss or damage if the information provided by You, or
                the service facility, cannot be verified as accurate.
              </li>
              <li>
                For any loss or damage if the Contract Purchase Date is more
                than thirty (30) days after the date that You purchased or
                leased the Vehicle from the Selling Dealer.
              </li>
              <li>
                In the event that the Vehicle is a used vehicle on the Contract
                Purchase Date, for any loss or damage if the Contract Purchase
                Date is more than ten (10) years after the date upon which the
                original purchaser or lessee first purchased or leased, as
                applicable, the Vehicle.
              </li>
            </ol>
            <h6>8. PROCEDURE FOR SERVICE</h6>
            <p className="first-para">
              In order to obtain the services from Extended Trust, either
              contact your original selling Dealer, an authorized service
              facility, or Extended Trust directly. Extended Trust can by
              reached by telephone (778-229-8847), e-mail
              (support@extendedtrust.ca), or by submitting a request for service
              online at www.theExtended Trust.ca.
            </p>
            <div>
              <h6>9. CONDITIONS OF SERVICE </h6>
              <p className="first-para">You acknowledge and agree that:</p>
            </div>
            <ol type="1">
              <li>
                Some services may require several appointments in order to
                resolve an imperfection;
              </li>
              <li>
                The completion of some Detailing services may require that You
                go without the use of the Vehicle for up to one(1) day;
              </li>
              <li>
                In many cases, it is impossible to resolve every imperfection
                You bring in for servicing. Extended Trust will have fulfilled
                its obligation under this Contract if, the judgment of a
                Extended Trust Inc. technician, best efforts have been made to
                effect the services utilizing the techniques used by Us in the
                normal course of business;
              </li>
              <li>
                As Extended Trust Inc. does not include the washing/vacuuming of
                a vehicle in their Detailing services, You must clean/vacuum the
                area to be serviced prior to bringing the Vehicle in for
                servicing.
              </li>
            </ol>
            <div>
              <h6>10. TRANSFERRING THIS CONTRACT </h6>
              <p className="first-para">
                Subject as hereinafter provided, only the original Customer may
                transfer or assign this Contract. This Contract may only be
                transferred or assigned in the case of a sale to a private
                party. This Contract applies only to the Vehicle and may not be
                transferred to any other vehicle. Under no circumstances will
                the Term be extended by the transfer of this Contract. The
                original Customer may transfer or assign this Contract to a
                private party by contacting a Extended Trust Agent, and
                providing the following information:
              </p>
            </div>
            <ol type="1">
              <li>New Owner’s First and Last Name;</li>
              <li>
                New Owner’s Address, including City, Province, and Postal Code;
              </li>
              <li>New Owner’s Phone Number; and</li>
              <li>New Owner’s Email Address</li>
            </ol>
            <p className="first-para">
              This information must be provided to Extended Trust Inc. within 30
              days of the sale or transfer of the Vehicle. For the purposes of
              this Contract, a “private party” will include any individual who
              is a non-corporate entity and any individual who is not in the
              business of purchasing and selling automobiles. We may transfer or
              assign this Contract at any time in whole or in part.
            </p>

            <div>
              <h6>11. CANCELLING THIS CONTRACT </h6>
              <p className="first-para">
                You may cancel this Contract within thirty (30) days of the
                Effective Date and receive a refund of 100% of the Fee, provided
                that no Services have been performed under this Contract. [The
                refund will be paid by Us to the dealer and You shall be
                responsible for collecting the refund from the Dealer directly.]
                In the event that this Contract is not cancelled within 30 days
                of commencement as outlined herein, the full fee paid to
                Extended Trust Inc. is deemed to have been earned by Extended
                Trust Inc. We may cancel this Contract at any time by refunding
                You a pro rated amount of the Fee, based on the amount of time
                remaining on the Term. [The refund will be paid by Us to the
                Dealer and You shall be responsible for collecting the refund
                from the Dealer directly.] This Contract will terminate
                automatically:
              </p>
            </div>
            <ol type="1">
              <li>if the Vehicle is written off, repossessed, or stolen;</li>
              <li>
                if the Vehicle is sold to a third party without contacting
                Extended Trust Inc.; c. at the expiry of the term.
              </li>
            </ol>
            <h6>12. GENERAL</h6>
            <ol type="1">
              <li>
                This Contract may be executed by any party hereto by his signing
                a counterpart hereof, each of which counterparts so executed
                shall be deemed to be an original, and such counterparts
                together shall constitute a single instrument.
              </li>
              <li>
                Headings and sections hereof are inserted for convenience of
                reference only and shall not affect the construction and
                interpretation of this Contract.
              </li>
              <li>
                This Contract shall be interpreted in accordance with the laws
                of the Province of Alberta and the courts of said Province shall
                have jurisdiction to hear all matters arising hereunder.
              </li>
              <li>
                This Contract constitutes the entire agreement between parties.
                This Contract may not be amended or modified in any respect,
                except in written instrument signed by both parties.
              </li>
              <li>
                You acknowledge that Extended Trust Inc. is not providing
                insurance, but rather, a prepaid service program for a fixed
                period of time during which Extended Trust Inc. will use
                proprietary techniques and products to address cosmetic
                imperfections that can occur from time to time. Although
                Extended Trust Inc. will make reasonable efforts to resolve
                imperfections in accordance with the process outlined herein,
                Extended Trust Inc. cannot guarantee that imperfections will be
                removed using the services that Extended Trust provides and,
                accordingly, it is strongly recommended that You maintain, at
                all times, a comprehensive policy of automotive insurance with
                coverage for damage that might occur to the Vehicle.
              </li>
            </ol>
            <p>For Service assistance, please contact Us at 778-229-8847</p>
          </div>
        </div>
      </section>

      <Modal show={showmodal} className="confirmmodal" centered>
        <Modal.Body>
          <Icon
            icon="mdi:tick-circle"
            width="60"
            height="60"
            style={{ color: "#4A6543" }}
          />
          <h4>Thank You!</h4>
          <p>Email was successfully sent to {`${data[0]?.customer?.email}`}</p>
          <button onClick={() => setShowmodal(false)}>OK</button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Contractpdf;
