import React, { useContext, useEffect, useMemo } from "react";
import { parseBooleanVal } from "../../../../../utils/utils";
import { isArray } from "lodash";
import RegistrationContext from "../../registrationContext/registrationContext";

const OfferAddOnService = (props) => {
  const {
    item,
    index,
    checkBoxesForServices,
    isPricingVisible,
    serviceCategoryObj,
    priceObj,
    handleCheckedServicesByTenure,
    vehical,
    serviceTierOptions,
  } = props;

  const { setCheckBoxesForServices } = useContext(RegistrationContext);

  const findCarTier = (carMake) => {
    const tier = serviceTierOptions.find(
      (tier) => tier.match.findIndex((x) => x === carMake.toUpperCase()) !== -1
    );
    return tier || null;
  };

  const isTierCheckedId = useMemo(() => {
    if (serviceCategoryObj.categoryName.includes("Trust Shield") && vehical?.make) {
      return findCarTier(vehical.make)?._id;
    }
    return false;
  }, [vehical]);

  const priceObject =
    serviceCategoryObj.categoryName.includes("Trust Shield")
      ? isArray(priceObj)
        ? priceObj.find((x) => x.serviceTier === isTierCheckedId)
        : null
      : priceObj;

  const defaultCheckFn = (val = true) => {
    const isExists = checkBoxesForServices?.hasOwnProperty(
      isTierCheckedId !== false &&
        isTierCheckedId !== null &&
        serviceCategoryObj.categoryName.includes("Trust Shield")
        ? `${serviceCategoryObj.categoryId}-${priceObject?.serviceTenure}-${item.programId}-${priceObject?._id}-${isTierCheckedId}`
        : `${serviceCategoryObj.categoryId}-${priceObject?.serviceTenure}-${item.programId}-${priceObject?._id}`
    );

    if (val === false && isExists === true) {
      handleCheckedServicesByTenure(
        serviceCategoryObj.categoryId,
        item.programId,
        priceObject?.serviceTenure,
        priceObject?._id,
        false,
        isTierCheckedId
      );
    } else if (isExists === false) {
      handleCheckedServicesByTenure(
        serviceCategoryObj.categoryId,
        item.programId,
        priceObject?.serviceTenure,
        priceObject?._id,
        true,
        isTierCheckedId
      );
    }
  };

  const checkRequiredAndVechicleType = () => {
    if (parseBooleanVal(item.isRequired)) {
      defaultCheckFn();
    }

    if (item.vehicleType !== null) {
      const VechicalType = vehical?.productType?.toLowerCase();
      if (
        ((item.vehicleType === "new" && VechicalType === "new") ||
        (item.vehicleType === "new" && VechicalType === "demo")) && !item.programName.includes("Tire Store")
      ) {
        defaultCheckFn();
      }
      if (
        (item.vehicleType === "used" && VechicalType === "used") ||
        (item.vehicleType === "used" && VechicalType === "cpo")
      ) {
        defaultCheckFn();
      }
    }
  };

  useEffect(() => {
    checkRequiredAndVechicleType();
  }, [item, vehical, checkBoxesForServices]);

  const findKeyName = (cId, tId, programId) => {
    const searchPattern = `${cId}-${tId}-${programId}-`;

    const keyName = Object.keys(checkBoxesForServices || {}).find((key) =>
      key.startsWith(searchPattern)
    );

    return keyName;
  };

  const handleWheelDriveCheckboxes = (
    categoryId,
    tenureId,
    changeProgramId,
    isChecked
  ) => {
    const findKey = findKeyName(categoryId, tenureId, changeProgramId);
    if (findKey !== undefined) {
      setCheckBoxesForServices((prev) => ({
        ...prev,
        [`${serviceCategoryObj.categoryId}-${priceObject?.serviceTenure}-${item.programId}-${priceObject?._id}`]:
          isChecked,
        [findKey]: false,
      }));
      return;
    }
    setCheckBoxesForServices((prev) => ({
      ...prev,
      [`${serviceCategoryObj.categoryId}-${priceObject?.serviceTenure}-${item.programId}-${priceObject?._id}`]:
        isChecked,
    }));
  };

  // if (
  //   serviceCategoryObj.categoryId === "65de06875a4edc7aca3a08b3" &&
  //   (item.programName === "All Wheel Drive (All Wheel)" ||
  //     item.programName === "All Wheel Drive (Two-Wheel Drive)")
  // ) {
  //   console.log("checkBoxesForServices", checkBoxesForServices);
  //   console.log("item", item);
  // }

  const checkIsDisabled = () => {
    const VechicalType = vehical?.productType?.toLowerCase();
    if (parseBooleanVal(item.isRequired)) return true;
    if (
      (item.vehicleType === "used" && VechicalType === "used") ||
      (item.vehicleType === "used" && VechicalType === "cpo") 
    ) {
      return true;
    }
    if (
      ((item.vehicleType === "new" && VechicalType === "new") ||
      (item.vehicleType === "new" && VechicalType === "demo") ) && !item.programName.includes("Tire Store")
    ) {
      return true;
    }
    return false;
  };

  const checkDefaultChecked = useMemo(() => {
    if (isTierCheckedId === false) {
      return (
        parseBooleanVal(
          checkBoxesForServices[
            `${serviceCategoryObj.categoryId}-${priceObject?.serviceTenure}-${item.programId}-${priceObject?._id}`
          ]
        ) || false
      );
    }

    return (
      parseBooleanVal(
        checkBoxesForServices[
          `${serviceCategoryObj.categoryId}-${priceObject?.serviceTenure}-${item.programId}-${priceObject?._id}-${isTierCheckedId}`
        ]
      ) || false
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBoxesForServices]);

  const isVechiTypeIsSameForService = () => {
    const VechicalType = vehical?.productType?.toLowerCase();
    if (
      (item.vehicleType === "new" && VechicalType === "new") ||
      (item.vehicleType === "new" && VechicalType === "demo")
    ) {
      return true;
    }
    if (
      (item.vehicleType === "used" && VechicalType === "used") ||
      (item.vehicleType === "used" && VechicalType === "cpo")
    ) {
      return true;
    }
    return false;
  };

  if (item.vehicleType !== null && isVechiTypeIsSameForService() === false) {
    if (
      item.VechicalType !== null &&
      checkBoxesForServices?.hasOwnProperty(
        `${serviceCategoryObj.categoryId}-${priceObject?.serviceTenure}-${item.programId}-${priceObject?._id}`
      )
    ) {
      handleCheckedServicesByTenure(
        serviceCategoryObj.categoryId,
        item.programId,
        priceObject?.serviceTenure,
        priceObject?._id,
        false,
        isTierCheckedId
      );
    }
    return <></>;
  }

  return (
    <div className="offerandservice1 mb-3" key={index}>
      <div className="servicenamewrapper1">
        <p>
          {/* Trust Shield and custom Trust Shield programs */}
          {(item.programId === "65e230eed1d6e8c0fdf1cffd" || item.programId === "66d152b28d73ff1e2fa897c8") &&
            serviceTierOptions
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((tier, index) => (
                <label
                  key={index}
                  className="serviceChecks bg-transparent d-flex justify-content-center"
                >
                  <div className="d-flex align-items-start justify-content-between w-100 flex-column">
                    <div>
                      <input
                        disabled={true}
                        type="checkbox"
                        defaultChecked={
                          isTierCheckedId ? isTierCheckedId === tier._id : false
                        }
                        checked={
                          isTierCheckedId ? isTierCheckedId === tier._id : false
                        }
                        // name={item.programName}
                      />
                      {tier.tierName}
                    </div>
                    <span className="tier-match">{tier.match.join(",")}</span>
                  </div>
                  {/* {isTierCheckedId && isTierCheckedId === tier._id && (
                    <span className="ps-4 mt-1">
                      {isPricingVisible && (
                        <strong>{`$ ${priceObject?.price}`}</strong>
                      )}
                    </span>
                  )} */}
                </label>
              ))
          }

          <label className="serviceChecks bg-transparent d-flex justify-content-center">
            <div className="d-flex align-items-start justify-content-between w-100 flex-column">
              <div
                className={
                  item.programId === "65e23040d2d50301cffe9e43" ||
                  item.programId === "65e23040d2d50301cffe9e44"
                    ? "pb-4"
                    : ""
                }
              >
                <input
                  disabled={checkIsDisabled()}
                  type="checkbox"
                  defaultChecked={checkDefaultChecked}
                  checked={checkDefaultChecked}
                  name={item.programName}
                  id={item.programId}
                  onChange={(e) => {
                    const isChecked = e.target.checked;

                    if (
                      item.programId === "65e23040d2d50301cffe9e46" ||
                      item.programId === "65e23040d2d50301cffe9e45"
                    ) {
                      handleWheelDriveCheckboxes(
                        serviceCategoryObj.categoryId,
                        priceObject?.serviceTenure,
                        item.programId === "65e23040d2d50301cffe9e46"
                          ? "65e23040d2d50301cffe9e45"
                          : "65e23040d2d50301cffe9e46",
                        isChecked
                      );
                      return;
                    }

                    if (
                      item.programId === "65e23040d2d50301cffe9e43" ||
                      item.programId === "65e23040d2d50301cffe9e44"
                    ) {
                      handleWheelDriveCheckboxes(
                        serviceCategoryObj.categoryId,
                        priceObject?.serviceTenure,
                        item.programId === "65e23040d2d50301cffe9e43"
                          ? "65e23040d2d50301cffe9e44"
                          : "65e23040d2d50301cffe9e43",
                        isChecked
                      );
                      return;
                    }

                    handleCheckedServicesByTenure(
                      serviceCategoryObj.categoryId,
                      item.programId,
                      priceObject?.serviceTenure,
                      priceObject?._id,
                      isChecked,
                      isTierCheckedId
                    );
                  }}
                />
                {item.programName}
              </div>
              {item.programId === "65e23040d2d50301cffe9e43" && (
                <span className="error-message">
                  Only select this surcharge if RIM Diameter is over 20" or if
                  the TIRE Speed Rating is , W, or Y.
                </span>
              )}
              {item.programId === "65e23040d2d50301cffe9e44" && (
                <span className="error-message">
                  If the vehicle's tires fall into both speed-rated/Oversized &
                  Run-flat, the customer and dealership only pay the highest
                  value surcharge would be be Run-flat.
                </span>
              )}
            </div>
            <span className="ps-4 mt-1">
              {isPricingVisible && Number(priceObject?.price) > 0 && (
                <strong>{`$ ${priceObject?.price ?? ""}`}</strong>
              )}
            </span>
          </label>
        </p>
      </div>
    </div>
  );
};

export default OfferAddOnService;
