import React from "react";
import Image1 from "../../../../../asset/image1.svg";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
  sumOfProgramsPrices,
} from "../../../../../utils/utils";
import moment from "moment";

const CustomerInformation = ({ data, editReport }) => {
  // console.log("editReport CustomerInformation: ", editReport);

  const renderProgramRows = (programs, purchaseDate) => {
    return programs.map((program) => {
      const tenureDuration = program.priceDetails.serviceTenure.tenureDuration;
      const expiryDate = calculateExpiryDate(purchaseDate, tenureDuration);

      return (
        <>
          <tr className="c34">
            <td className="c41" colspan="5" rowspan="1">
              <p className="c21">
                <span className="c3">{program.programName}</span>
              </p>
            </td>
            <td className="c57" colspan="1" rowspan="1">
              <p className="c1">
                <span className="c15"></span>
              </p>
            </td>
            <td className="c20" colspan="1" rowspan="1">
              <p className="c1">
                <span className="c15"></span>
              </p>
            </td>
            <td className="c14" colspan="1" rowspan="1">
              <p className="c4">
                <span className="c3">{tenureDuration}</span>
              </p>
            </td>
            {/* <td className="c17" colspan="1" rowspan="1">
              <p className="c1">
                <span className="c15"></span>
              </p>
            </td> */}
            <td className="c26" colspan="1" rowspan="1">
              <p className="c21 c56" style={{ paddingRight: "50px" }}>
                <span className="c3">{expiryDate}</span>
              </p>
            </td>
          </tr>
        </>
      );
    });
  };

  const calculateExpiryDate = (purchaseDate, tenureDuration) => {
    const duration = parseInt(tenureDuration.split(" ")[0], 10);
    const purchase = new Date(purchaseDate);
    purchase.setMonth(purchase.getMonth() + duration);

    return purchase.toISOString().split("T")[0];
  };

  const subtotalRetail = data?.reduce((acc, item) => {
    const retailerPrice =
      Number(item.retailerPrice) || sumOfProgramsPrices(item.programs);
    const programPrices = Number(sumOfProgramsPrices(item.programs));
    const total = retailerPrice !== 0 ? retailerPrice : programPrices;
    return acc + total;
  }, 0);

  return (
    <>
      {/* <div className="break"></div> */}
      <table className="c75">
        <tr className="c122">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c21">
              <span
                style={{
                  display: "inline-block",

                  width: "160.00px",
                  height: "66.80px",
                }}
              >
                <img
                  alt=""
                  src={Image1}
                  style={{
                    width: "160.00px",
                    height: "66.80px",
                  }}
                  title=""
                />
              </span>
            </p>
          </td>
          <td className="c31" colspan="6" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          {/* <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c9" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td> */}
          {/* <td className="c16" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td> */}
          {/* <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td> */}
          {/* <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>*/}
          {/* <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td> */}
          <td
            className="c14"
            colspan="1"
            rowspan="1"
            style={{ paddingLeft: "20px" }}
          >
            <p
              className="c1"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <span className="c15">
                Reference#: {editReport.agreementNumber}
              </span>
            </p>
          </td>
        </tr>
        {/* <tr className="c81">
          <td className="c54 umer 2" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c9" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c16" colspan="1" rowspan="2">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
        </tr> */}

        {/* Customer Information Start */}
        <tr className="c122">
          <td className="c159" colspan="5" rowspan="1">
            <p className="c21 c44">
              <span className="c6">CUSTOMER INFORMATION</span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          {/* <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td> */}
        </tr>
        <tr className="c61">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>

          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        <tr className="c58">
          <td className="c41" colspan="3" rowspan="1">
            <p className="c21">
              <span className="c35">NAME</span>
            </p>
          </td>
          <td className="c57" colspan="2" rowspan="1">
            <p className="c21 c53" style={{ paddingLeft: "10px" }}>
              <span className="c35">PHONE</span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c21 c45">
              <span className="c35">EMAIL ADDRESS</span>
            </p>
          </td>
          {/* <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"> </span>
            </p>
          </td> */}
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c102" style={{ paddingLeft: "10px" }}>
              <span className="c35">ADDRESS</span>
            </p>
          </td>
        </tr>
        <tr className="c107">
          <td class="c88" colspan="3" rowspan="2">
            <p class="c21 c45">
              <span class="c3">
                {capitalizeFirstLetter(
                  `${editReport?.customer?.firstName} ${editReport?.customer?.lastName}`
                )}
              </span>
            </p>
          </td>
          <td className="c88" colspan="2" rowspan="1">
            <p className="c21 c45" style={{ paddingLeft: "10px" }}>
              <span className="c3">{editReport?.customer?.phone}</span>
            </p>
          </td>
          <td className="c88" colspan="2" rowspan="2">
            <p className="c21 c45">
              <span className="c3">{editReport?.customer?.email}</span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c102" style={{ paddingLeft: "10px" }}>
              <span className="c3">{editReport?.customer?.address}</span>
              <br />
              <span className="c3">
                {editReport?.customer?.city}, {editReport?.customer?.state}
              </span>
              <br />
              <span className="c3">{editReport?.customer?.postalCode}</span>
            </p>
          </td>
        </tr>
        <tr className="c152">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c9" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c16" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c10"></span>
            </p>
          </td>
        </tr>
        <tr className="c61">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c82" colspan="2" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        {/* Customer Information End */}

        {/* Vehicle Start */}
        <tr className="c122">
          <td className="c149" colspan="3" rowspan="1">
            <p className="c21 c44">
              <span className="c6">VEHICLE INFORMATION</span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
        </tr>
        <tr className="c61">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>

          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        <tr className="c58">
          <td className="c41" colspan="3" rowspan="1">
            <p className="c21">
              <span className="c12">VEHICLE PURCHASE/LEASE DATE</span>
            </p>
          </td>
          <td className="c57" colspan="2" rowspan="1">
            <p className="c21 c53">
              <span className="c12">YEAR</span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c21 c63">
              <span className="c12">MANUFACTURER</span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c21 c111" style={{ paddingRight: "10px" }}>
              <span className="c12">MODEL</span>
            </p>
          </td>

          <td className="c26" colspan="1" rowspan="1">
            <p className="c21">
              <span className="c12">VEHICLE IDENTIFICATION NUMBER</span>
            </p>
          </td>
        </tr>
        <tr className="c107">
          <td class="c88" colspan="3" rowspan="2">
            <p class="c21 c45">
              <span class="c3">
                {moment(editReport?.customer?.vehical?.purchaseDate).format(
                  "MMMM DD, YYYY"
                )}
              </span>
            </p>
          </td>
          <td className="c88" colspan="2" rowspan="1">
            <p className="c21 c45">
              <span className="c22">
                {moment(editReport?.customer?.vehical?.year).format("YYYY")}
              </span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c21 c63">
              <span className="c22">
                {editReport?.customer?.vehical?.make?.toUpperCase()}
              </span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c21 c111" style={{ paddingRight: "10px" }}>
              <span className="c22">
                {editReport?.customer?.vehical?.modal}
              </span>
            </p>
          </td>

          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c129">
              <span className="c22">{editReport?.customer?.vehical?.vin}</span>
            </p>
          </td>
        </tr>
        <tr className="c112">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c156" colspan="2" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c82" colspan="2" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        {/* Vehicle End */}

        <tr className="c122">
          <td className="c76" colspan="4" rowspan="1">
            <p className="c21 c44">
              <span className="c6">SERVICES INFORMATION</span>
            </p>
          </td>

          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
        </tr>
        <tr className="c61">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c82" colspan="2" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        <tr className="c58">
          <td className="c41" colspan="4" rowspan="1">
            <p className="c21">
              <span className="c35">SERVICES INCLUDED</span>
            </p>
          </td>
          {/* <td className="c57" colspan="2" rowspan="1">
            <p className="c21 c53">
              <span className="c12"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c21 c63">
              <span className="c12"></span>
            </p>
          </td> */}
          <td className="c14" colspan="1" rowspan="1">
            <p className="c21 c111" style={{ paddingRight: "10px" }}>
              <span className="c12">Term</span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c21 111" style={{ paddingRight: "50px" }}>
              <span className="c12">EXPIRY DATE</span>
            </p>
          </td>
        </tr>
        {/* Map */}
        {data.map((category) => (
          <>
            <tr className="c122">
              <td className="c41" colspan="10" rowspan="1">
                <p className="c21">
                  <span className="c35">{category.categoryName}</span>
                </p>
              </td>
            </tr>
            {renderProgramRows(category.programs, category.purchaseDate)}
            <tr className="c61">
              <td className="c31" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c25"></span>
                </p>
              </td>
              <td className="c42" colspan="1" rowspan="2">
                <p className="c1">
                  <span className="c25"></span>
                </p>
              </td>
              <td className="c86" colspan="4" rowspan="2">
                <p className="c1">
                  <span className="c25"></span>
                </p>
              </td>
              <td className="c57" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c25"></span>
                </p>
              </td>
              <td className="c20" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c25"></span>
                </p>
              </td>
              <td className="c14" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c25"></span>
                </p>
              </td>
              <td className="c17" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c25"></span>
                </p>
              </td>
              <td className="c26" colspan="1" rowspan="1">
                <p className="c1">
                  <span className="c25"></span>
                </p>
              </td>
            </tr>
          </>
        ))}

        {/* Totals Start */}
        <tr className="c122">
          <td className="c31" colspan="1" rowspan="1">
            <p className="c21 c44">
              <span className="c6">Totals</span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
        </tr>
        <tr className="c61">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>

          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        <tr className="c58">
          <td className="c41" colspan="3" rowspan="1">
            <p className="c21">
              <span className="c35">RETAIL COST</span>
            </p>
          </td>
          <td className="c57" colspan="2" rowspan="1">
            <p className="c21 c53" style={{ paddingLeft: "10px" }}>
              <span className="c35">GST</span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c21 c45" style={{ paddingLeft: "20px" }}>
              <span className="c35">PST</span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c102">
              <span className="c35">GRAND TOTAL</span>
            </p>
          </td>
        </tr>
        <tr className="c107">
          <td class="c88" colspan="3" rowspan="2">
            <p class="c21 c45">
              <span class="c3">${subtotalRetail?.toFixed(2)}</span>
            </p>
          </td>
          <td className="c88" colspan="2" rowspan="1">
            <p className="c21 c45" style={{ paddingLeft: "10px" }}>
              <span className="c3">
                $
                {(
                  (subtotalRetail || 0) *
                  (editReport?.customer?.gst / 100)
                )?.toFixed(2)}
              </span>
            </p>
          </td>
          <td className="c88" colspan="2" rowspan="2">
            <p className="c21 c45" style={{ paddingLeft: "20px" }}>
              <span className="c3">
                $
                {(
                  (subtotalRetail || 0) *
                  ((editReport?.customer?.pst || 0) / 100)
                )?.toFixed(2)}
              </span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c102">
              <span className="c3" style={{ display: "block", width: "50%" }}>
                $
                {(
                  (subtotalRetail || 0) +
                  (subtotalRetail || 0) *
                    ((editReport?.customer?.pst || 0) / 100) +
                  (subtotalRetail || 0) * (editReport?.customer?.gst / 100)
                )?.toFixed(2)}
              </span>
            </p>
          </td>
        </tr>
        <tr className="c97">
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c9" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c16" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c45">
              <span className="c3"></span>
            </p>
          </td>
        </tr>
        {/* Totals End */}

        {/* Dealer Information Start */}
        <tr className="c122">
          <td className="c31" colspan="1" rowspan="1">
            <p className="c21 c44">
              <span className="c6">SELLING DEALER</span>
            </p>
          </td>

          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
        </tr>
        <tr className="c61">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>

          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        <tr className="c58">
          <td className="c41" colspan="3" rowspan="1">
            <p className="c21">
              <span className="c35">DEALERSHIP NAME</span>
            </p>
          </td>
          <td className="c57" colspan="2" rowspan="1">
            <p className="c21 c53" style={{ paddingLeft: "10px" }}>
              <span className="c35">DEALERSHIP CONTACT PERSON</span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c21 c45" style={{ paddingLeft: "20px" }}>
              <span className="c35">TELEPHONE</span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"> </span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c102">
              <span className="c35">ADDRESS</span>
            </p>
          </td>
        </tr>
        <tr className="c107">
          <td class="c88" colspan="3" rowspan="2">
            <p class="c21 c45">
              <span class="c3">
                {capitalizeAllFirstLetters(`${editReport.dealer?.name}`)}
              </span>
            </p>
          </td>
          <td className="c88" colspan="2" rowspan="1">
            <p className="c21 c45" style={{ paddingLeft: "10px" }}>
              <span className="c3">
                {capitalizeAllFirstLetters(
                  `${editReport.dealer?.firstName} ${editReport.dealer?.lastName}`
                )}
              </span>
            </p>
          </td>
          <td className="c88" colspan="2" rowspan="2">
            <p className="c21 c45" style={{ paddingLeft: "20px" }}>
              <span className="c3">{editReport.dealer?.phone}</span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c102">
              <span className="c3" style={{ display: "block", width: "50%" }}>
                {editReport.dealer?.address}
                <br />
                {editReport.dealer?.city}, {editReport.dealer?.state}
                <br />
                {editReport.dealer?.postalCode}
              </span>
            </p>
          </td>
        </tr>
        <tr className="c97">
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c9" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c16" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c21 c45">
              <span className="c3"></span>
            </p>
          </td>
        </tr>
        {/* Dealer Information End */}

        <tr className="c122">
          <td className="c60" colspan="2" rowspan="1">
            <p className="c21 c53">
              <span className="c6">OUR INFORMATION</span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c11"></span>
            </p>
          </td>
        </tr>
        <tr className="c61">
          <td className="c54" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c31" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c42" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c7" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c30" colspan="1" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c82" colspan="2" rowspan="2">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        <tr className="c104">
          <td className="c41" colspan="6" rowspan="1">
            <p className="c21">
              <span className="c35">ExtendedTrust</span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c20" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c14" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c15"></span>
            </p>
          </td>
        </tr>
        <tr className="c91">
          <td className="c41" colspan="6" rowspan="1">
            <p className="c21">
              <span className="c3">
                Unit 200-11729 105 Ave. Edmonton, AB T5H0L9
              </span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c77" colspan="2" rowspan="1">
            <p className="c21 c79">
              <span className="c3">Phone: 888 418 1711</span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c25"></span>
            </p>
          </td>
        </tr>
        <tr className="c97">
          <td className="c41" colspan="6" rowspan="1">
            <p className="c21">
              <span className="c3">T5V 1A3</span>
            </p>
          </td>
          <td className="c57" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c77" colspan="2" rowspan="1">
            <p className="c21 c79">
              <span className="c3"></span>
            </p>
          </td>
          <td className="c17" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
          <td className="c26" colspan="1" rowspan="1">
            <p className="c1">
              <span className="c67"></span>
            </p>
          </td>
        </tr>
      </table>
      <p className="c1">
        <span className="c19"></span>
      </p>
    </>
  );
};

export default CustomerInformation;
